<div *ngFor="let alert of alerts" class="alert {{ cssClass(alert) }} alert-dismissable slide_animation_forwards" (click)="removeAlert(alert, $event, false)">
  {{alert.message}}
  <a class="close" (click)="removeAlert(alert, $event, true)">&times;</a>
</div>


<!--<div class="alert alert-success alert-dismissable slide_animation_forwards">
  this is an success alert
  <a class="close">&times;</a>
</div>
<div class="alert alert-danger alert-dismissable slide_animation_forwards">
  this is an error alert message
  <a class="close">&times;</a>
</div>
<div class="alert alert-info alert-dismissable slide_animation_forwards">
  this is an info alert message
  <a class="close">&times;</a>
</div>
<div class="alert alert-warning alert-dismissable slide_animation_forwards">
  this is an warning alert
  <a class="close">&times;</a>
</div>-->
